import React from 'react';
import Helmet from 'react-helmet';
import marked from 'marked';
import { navigate } from 'gatsby';
import commentBox from 'commentbox.io';

import HeaderSecondary from '../_global/HeaderSecondary.jsx';
import Banner from '../_global/Banner.jsx';
import MainLayout from '../layouts/MainLayout.jsx';

import './BlogArticlePage.scss';

class BlogArticlePage extends React.Component {


    componentDidMount() {

        this.removeCommentBox = commentBox(process.env.GATSBY_PROJECT_ID);
    }

    componentWillUnmount() {

        this.removeCommentBox();
    }

    onGoBack = e => {

        navigate('/blog');
    };

    render() {

        const { pathContext: { post: {
            title,
            slug,
            description: { description },
            body: { body },
            tags,
            heroImage,
            createdAt
        } } } = this.props;

        const url = `https://commentbox.io/blog/${slug}/`;
        const [ year, month, date ] = createdAt.split('T')[0].split('-');

        return (
            <MainLayout>

                <Helmet>

                    <title>CommentBox.io - {title}</title>
                    <meta property="description" content={description} />
                    <meta property="keywords" content={tags.join(', ')} />

                    <meta property="og:title" content={title} />
                    <meta property="og:url" content={url} />
                    <meta property="og:description" content={description} />
                    <meta property="og:image" content={heroImage ? `https:${heroImage.file.url}` : ''} />

                    <meta name="twitter:title" content={title} />
                    <meta name="twitter:description" content={description} />
                    <meta name="twitter:image" content={heroImage ? `https:${heroImage.file.url}` : ''} />

                </Helmet>

                <div className="blog-article-page">

                    <HeaderSecondary />

                    <Banner>
                        <div className="blog-search active">

                            <input
                                className="form-control"
                                type="search"
                                name="query"
                                value={title}
                                readOnly={true}
                            />
                            <button type="button" onClick={this.onGoBack}>x</button>
                        </div>
                    </Banner>

                    <div className="container">

                        <div className="row">

                            <div className="col-lg-8 col-lg-offset-2 col-md-10 col-md-offset-1">

                                <h1>
                                    {title}
                                </h1>
                                <sub>
                                    Posted on {month}/{date}/{year} by <a target="_blank" href="https://twitter.com/shaunpersad">@shaunpersad</a>
                                </sub>

                                <div className="hero">
                                    { heroImage ? (
                                        <img src={heroImage.file.url} />
                                    ) : null }
                                </div>
                                <hr />
                                <div
                                    className="post-content"
                                    dangerouslySetInnerHTML={{
                                        __html: marked(body),
                                    }}
                                />

                                <hr />

                                <p className="announcement">
                                    Want to stay on top of product announcements? Follow us on Twitter at <a target="_blank" href="https://twitter.com/commentbox_io/">@CommentBox_io</a>.
                                </p>

                                <div className="commentbox" />


                            </div>
                        </div>


                    </div>

                </div>

            </MainLayout>
        );
    }
}

export default BlogArticlePage;