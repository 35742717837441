import React from 'react';
import {Link} from "gatsby";
import logo from '../../images/logo.svg';

import './HeaderSecondary.scss';

const isActive = ({ isCurrent, isPartiallyCurrent }) => {

    const props = {
        className: 'btn btn-link'
    };
    if (isCurrent || isPartiallyCurrent) {
        props.className+= ' selected';
    }

    return props;
};

function HeaderSecondary() {

    return (

        <header className="header-secondary container-fluid alt-bg">
            <nav className="navbar">
                <div className="navbar-header">
                    <Link className="navbar-brand" to="/"><img src={logo} alt="CommentBox" /></Link>
                </div>

                <div className="navbar-right">

                    <Link className="btn btn-link" to="/">Home</Link>

                    <Link getProps={isActive} activeClassName="selected" className="btn btn-link" to="/blog">Blog</Link>

                    <Link getProps={isActive} activeClassName="selected" className="btn btn-link" to="/docs">Docs</Link>

                    <a href="https://dashboard.commentbox.io" target="_blank" className="btn btn-success">
                        Dashboard
                    </a>
                </div>
            </nav>
        </header>
    );
}

export default HeaderSecondary;