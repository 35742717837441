import React from 'react';

import './Banner.scss';

function Banner({ children }) {

    return (
        <div className="banner container-fluid">

            <div className="row">

                <div className="col-lg-4 col-lg-offset-8 col-md-6 col-md-offset-6 col-sm-8 col-sm-offset-4 col-xs-8 col-xs-offset-4">

                    <div className="banner-content">
                        { children }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Banner;